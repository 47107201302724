import React from "react";
import { INNER_CHOOSE_IMG } from "../../lib/assets";

export const ChooseAreaThree = () => {
  return (
    <section className="choose-area-three">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="choose-content-three">
              <div className="section-title-two white-title mb-30 ">
                <span className="sub-title ">
                    Keep Your Business Safe & Ensure High Availability
                </span>

                <div className="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 className="title tg-element-title">
                    Welcome to Compass Eight Management Consultancies
                  </h2>
                </div>

              </div>
              <p>

              </p>

              <div className="accordion-wrap-two">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Our Mission
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          At Compass Eight, our mission is to empower businesses with strategic insights and expert guidance to achieve sustainable growth and success. We are committed to delivering exceptional value to our clients by leveraging our expertise and industry knowledge.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                      >
                        Our Vision
                      </button>
                    </h2>
                    <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Our vision is to be the consulting partner of choice for businesses seeking to achieve sustainable growth and success. We aim to be recognized for our unwavering commitment to delivering exceptional value to our clients, and for our ability to provide innovative solutions that drive tangible results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Our Team
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          At Compass Eight, we believe that our people are our greatest asset. Our team comprises seasoned professionals with diverse backgrounds and expertise, united by a shared commitment to excellence. With a collaborative approach and a focus on innovation, we empower our clients to overcome challenges and seize opportunities for growth.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="choose-img-three">
              <img src={INNER_CHOOSE_IMG} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
