import React from "react";
import { SERVICES_ONE_LIST } from "../../mocks/services";
import { ServiceOneItem } from "./ServiceOneItem";

export const ServiceFive = () => {
  const list = [...SERVICES_ONE_LIST, ...SERVICES_ONE_LIST];
  list.length = 5;

  return (
    <section className="services-area-five inner-services-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-8 col-md-10">

            <div className="section-title mb-25 tg-heading-subheading animation-style2">
              <h2 className="title tg-element-title text-center">
                We offer a wide range of services
                designed to address the diverse needs of businesses across industries
              </h2>
            </div>

          </div>
        </div>

        <div className="row justify-content-center">
          {list.map((service) => (
            <div key={service.id} className="col-lg-4 col-md-6 col-sm-10">
              <ServiceOneItem service={service} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
