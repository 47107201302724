import React from "react";
import {
  FAQ_IMG01,
  FAQ_IMG02,
  FAQ_SHAPE02,
  FAQ_SHAPE03,
} from "../../lib/assets";

export const FaqAreaOne = () => {
  return (
    <section className="faq-area">
      <div className=""></div>

      <div className="faq-shape-wrap">
        <img src={FAQ_SHAPE02} alt="" />
      </div>

      <div className="container">
        <div className="row align-items-end justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="faq-img-wrap">
              <img src={FAQ_IMG01} alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="faq-content">
              <div className="section-title mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                    Our Commitments
                </span>
                <h2 className="title tg-element-title">
                    Keep Your Business Safe & Ensure High Availability
                </h2>
              </div>
              <p>
                We are dedicated to providing you with the best experience Business Management Services. We believe in a collaborative and customized approach to management consulting. By working closely with our clients, we gain deep insights into their businesses, enabling us to develop and implement strategies that are precisely aligned with their goals and vision.
              </p>

              <div className="accordion-wrap">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Experience
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          With years of experience in the industry, we have a deep understanding of the challenges businesses face and how to overcome them.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Expertise
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Our team of seasoned professionals brings expertise in various domains, ensuring comprehensive solutions tailored to your specific needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Client-Centric Approach
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          We prioritize the needs of our clients, working closely with them to develop customized strategies that drive results.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
