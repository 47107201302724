import React from "react";
import {Layout} from "../../layouts/Layout";
import {ServicesDetailsFaq} from "../../components/ServicesDetails/ServicesDetailsFaq";
import {ServicesDetailsFaqLegal} from "../../components/ServicesDetails/ServicesDetailsFaqLegal";
import {ServicesDetailsFaqInvestmenr} from "../../components/ServicesDetails/ServicesDetailsFaqInvestmenr";

import {
    CHECK_ICON,
    SD_VIDEO_IMG,
    SERVICES_DETAILS01,
    SERVICES_DETAILS011,
    SERVICES_DETAILS02, SERVICES_DETAILS03, SERVICES_DETAILS04, SERVICES_DETAILS05,
    SERVICES_IMG01, SERVICES_IMG02, SERVICES_IMG03, SERVICES_IMG04, SERVICES_IMG05
} from "../../lib/assets";
import {ServicesDetailsWrapper} from "../../components/ServicesDetails/ServicesDetailsWrapper";
import {VideoPlayerOne} from "../../components/VideoPlayers/VideoPlayerOne";
import {BrandSeven} from "../../components/Brand/BrandSeven";
import {useParams} from "react-router";
import {ServicesDetailsFaqInvestManage} from "../../components/ServicesDetails/ServicesDetailsFaqInvestManage";
import {ServicesDetailsFaqInvestCorporate} from "../../components/ServicesDetails/ServicesDetailsFaqInvestCorporate";

const ServicesDetailsPage = () => {

    let {id} = useParams();

    if (id === "marketing-research") {

        return (
            <Layout breadcrumb={"Services"} title={"Service Details"}>
                {/* services-details-area */}
                <ServicesDetailsWrapper hideContact hideTitle>
                    <div className="services-details-wrap">
                        <div className="services-details-thumb">
                            <img src={SERVICES_DETAILS01} alt=""/>
                        </div>

                        <div className="services-details-content">
                            <h2 className="title">
                                Uncover Insights, Drive Growth
                            </h2>
                            <p>
                                <p>
                                Comprehensive market assessments and competitive analyses provide businesses with the
                                insights needed to make informed strategic decisions. Our research and analysis services help
                                businesses stay ahead in a rapidly changing marketplace.
                                </p>
                                <ul>
                                    <li>Comprehensive market assessments to provide insights into market trends, customer behavior, and competitive dynamics.</li>
                                    <li>Competitive analysis to identify opportunities and threats, enabling you to make informed strategic decisions.</li>
                                </ul>
                            </p>
                            <p>
                                Our marketing research services provide valuable insights into market
                                trends, consumer behavior, and competitive dynamics. Through comprehensive analysis and
                                data-driven strategies, we help clients identify opportunities, mitigate risks, and optimize their
                                marketing efforts for maximum impact
                            </p>

                            <p>
                                <img className="m-top" src={SERVICES_DETAILS011} alt=""/>
                            </p>

                            <div className="sd-inner-wrap">
                                <div className="row align-items-center">
                                    <div className="col-56">
                                        <div className="content">
                                            <h3 className="title-two">
                                                Marketing Research Services
                                            </h3>
                                            <p>
                                                Our marketing research services provide valuable insights into market trends, consumer behavior, and competitive dynamics. Through comprehensive analysis and data-driven strategies, we help clients identify opportunities, mitigate risks, and optimize their marketing efforts for maximum impact.
                                            </p>
                                            <ul className="list-wrap">
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Comprehensive Market Assessments
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Competitive Analysis
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Data-Driven Strategies
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Market Trends
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-44">
                                        <div className="thumb">
                                            <img src={SERVICES_IMG01} alt=""/>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="company-benefit-wrap">
                                <h2 className="title-two">Company Key Benefits</h2>
                                {/* faq accordion */}
                                <ServicesDetailsFaq/>
                            </div>
                        </div>
                    </div>
                </ServicesDetailsWrapper>
            </Layout>
        );


    }

    if (id === "legal-expertise") {

        return (
            <Layout breadcrumb={"Services"} title={"Service Details"}>
                {/* services-details-area */}
                <ServicesDetailsWrapper hideContact hideTitle>
                    <div className="services-details-wrap">
                        <div className="services-details-thumb">
                            <img src={SERVICES_DETAILS02} alt=""/>
                        </div>

                        <div className="services-details-content">
                            <h2 className="title">
                                Legal Expertise
                            </h2>
                            <p>
                                <p>
                                    Our legal expertise services provide clients with the legal support and guidance they need to navigate complex legal matters. Our team of experienced attorneys and legal professionals offer a wide range of legal services to help clients protect their interests and achieve their goals.
                                </p>
                                <ul>
                                    <li>
                                        Legal Support and Guidance
                                    </li>
                                    <li>
                                        Experienced Attorneys and Legal Professionals
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Navigating the legal landscape can be challenging for businesses, which is why our legal expertise services are designed to provide clarity and guidance. From contract review and compliance to intellectual property protection and dispute resolution, our team of legal professionals offers expert counsel to help clients navigate legal complexities with confidence.
                            </p>

                            <p>
                                <img className="m-top" src={SERVICES_DETAILS011} alt=""/>
                            </p>

                            <div className="sd-inner-wrap">
                                <div className="row align-items-center">
                                    <div className="col-56">
                                        <div className="content">
                                            <h3 className="title-two">
                                                Legal Expertise Services
                                            </h3>
                                            <p>
                                                With our comprehensive legal expertise, we empower businesses to navigate legal complexities with confidence and focus on achieving their strategic objectives. Partner with Compass Eight Management Consultancies to access reliable legal guidance and mitigate legal risks effectively in today's dynamic business environment.
                                            </p>
                                            <ul className="list-wrap">
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Corporate Law Compliance
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Contract Drafting and Review
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Intellectual Property Protection
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Regulatory Compliance Advisory
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-44">
                                        <div className="thumb">
                                            <img src={SERVICES_IMG02} alt=""/>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="company-benefit-wrap">
                                <h2 className="title-two">Company Key Benefits</h2>
                                {/* faq accordion */}
                                <ServicesDetailsFaqLegal/>
                            </div>
                        </div>
                    </div>
                </ServicesDetailsWrapper>
            </Layout>
        );


    }

    if (id === "investment-research") {

        return (
            <Layout breadcrumb={"Services"} title={"Service Details"}>
                {/* services-details-area */}
                <ServicesDetailsWrapper hideContact hideTitle>
                    <div className="services-details-wrap">
                        <div className="services-details-thumb">
                            <img src={SERVICES_DETAILS03} alt=""/>
                        </div>

                        <div className="services-details-content">
                            <h2 className="title">
                                Investment Research
                            </h2>
                            <p>
                                <p>
                                    Our investment research services provide clients with the insights and analysis they need to make informed investment decisions. Our team of experienced analysts and financial professionals offer a wide range of research services to help clients identify opportunities and mitigate risks in today's dynamic investment landscape.
                                </p>
                                <ul>
                                    <li>
                                        In-Depth Market Analysis
                                    </li>
                                    <li>
                                        Comprehensive Investment Research
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Navigating the investment landscape can be challenging, which is why our investment research services are designed to provide clients with the insights and analysis they need to make informed decisions. From market analysis and competitor research to financial modeling and valuation, our team of analysts offers comprehensive research services to help clients identify opportunities and mitigate risks effectively.
                            </p>

                            <p>
                                <img className="m-top" src={SERVICES_DETAILS011} alt=""/>
                            </p>

                            <div className="sd-inner-wrap">
                                <div className="row align-items-center">
                                    <div className="col-56">
                                        <div className="content">
                                            <h3 className="title-two">
                                                Investment Research Services
                                            </h3>
                                            <p>
                                                At Compass Eight Management Consultancies, we understand the significance of informed investment decisions in achieving financial goals and maximizing returns. Our dedicated team of investment analysts specializes in providing comprehensive investment research services tailored to meet the diverse needs of our clients.

                                            </p>
                                            <ul className="list-wrap">
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    In-Depth Market Analysis
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Comprehensive Investment Research
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Financial Modeling and Valuation
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Competitor Research
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-44">
                                        <div className="thumb">
                                            <img src={SERVICES_IMG03} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="company-benefit-wrap">
                                <h2 className="title-two">Company Key Benefits</h2>
                                {/* faq accordion */}
                                <ServicesDetailsFaqInvestmenr/>
                            </div>
                        </div>
                    </div>
                </ServicesDetailsWrapper>
            </Layout>
        );


    }

    if (id === "management-systems") {

        return (
            <Layout breadcrumb={"Services"} title={"Service Details"}>
                {/* services-details-area */}
                <ServicesDetailsWrapper hideContact hideTitle>
                    <div className="services-details-wrap">
                        <div className="services-details-thumb">
                            <img src={SERVICES_DETAILS04} alt=""/>
                        </div>

                        <div className="services-details-content">
                            <h2 className="title">
                                Management Systems
                            </h2>
                            <p>
                                <p>
                                    Our management systems services provide businesses with the tools and resources they need to optimize their operations and achieve their strategic objectives. From quality management and environmental compliance to health and safety protocols, our management systems services are designed to help businesses enhance their performance and drive sustainable growth.
                                </p>
                                <ul>
                                    <li>
                                        Quality Management Systems
                                    </li>
                                    <li>
                                        Environmental Compliance
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Our management systems services help clients design, implement, and optimize systems and processes across their organizations, enabling them to streamline operations, improve productivity, and achieve their business objectives.
                            </p>

                            <p>
                                <img className="m-top" src={SERVICES_DETAILS011} alt=""/>
                            </p>

                            <div className="sd-inner-wrap">
                                <div className="row align-items-center">
                                    <div className="col-56">
                                        <div className="content">
                                            <h3 className="title-two">
                                                Management Systems Services
                                            </h3>
                                            <p>
                                                Our management systems services are designed to help businesses optimize their operations and achieve their strategic objectives. From quality management and environmental compliance to health and safety protocols, our management systems services provide businesses with the tools and resources they need to enhance their performance and drive sustainable growth.
                                            </p>
                                            <ul className="list-wrap">
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Quality Management Systems
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Environmental Compliance
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Health and Safety Protocols
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Process Optimization
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-44">
                                        <div className="thumb">
                                            <img src={SERVICES_IMG04} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="company-benefit-wrap">
                                <h2 className="title-two">Company Key Benefits</h2>
                                {/* faq accordion */}
                                <ServicesDetailsFaqInvestManage/>
                            </div>
                        </div>
                    </div>
                </ServicesDetailsWrapper>
            </Layout>
        );


    }

    if (id === "corporate-structures") {

        return (
            <Layout breadcrumb={"Services"} title={"Service Details"}>
                {/* services-details-area */}
                <ServicesDetailsWrapper hideContact hideTitle>
                    <div className="services-details-wrap">
                        <div className="services-details-thumb">
                            <img src={SERVICES_DETAILS05} alt=""/>
                        </div>

                        <div className="services-details-content">
                            <h2 className="title">
                                Corporate Structures
                            </h2>
                            <p>
                                <p>
                                    Our corporate structures services provide businesses with the guidance and support they need to establish and maintain effective corporate governance. From entity formation and compliance to board governance and shareholder relations, our corporate structures services are designed to help businesses establish a solid foundation for growth and sustainability.
                                </p>
                                <ul>
                                    <li>
                                        Entity Formation and Compliance
                                    </li>
                                    <li>
                                        Board Governance and Shareholder Relations
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Our corporate structures services help businesses establish and maintain effective corporate governance, enabling them to navigate complex regulatory requirements and build a solid foundation for growth and sustainability.
                            </p>

                            <p>
                                <img className="m-top" src={SERVICES_DETAILS011} alt=""/>
                            </p>

                            <div className="sd-inner-wrap">
                                <div className="row align-items-center">
                                    <div className="col-56">
                                        <div className="content">
                                            <h3 className="title-two">
                                                Corporate Structures Services
                                            </h3>
                                            <p>
                                                Our corporate structures services provide businesses with the guidance and support they need to establish and maintain effective corporate governance. From entity formation and compliance to board governance and shareholder relations, our corporate structures services are designed to help businesses establish a solid foundation for growth and sustainability.
                                            </p>
                                            <ul className="list-wrap">
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Entity Formation and Compliance
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Board Governance and Shareholder Relations
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Regulatory Compliance
                                                </li>
                                                <li>
                                                    <img src={CHECK_ICON} alt=""/>
                                                    Corporate Governance
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-44">
                                        <div className="thumb">
                                            <img src={SERVICES_IMG05} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="company-benefit-wrap">
                                <h2 className="title-two">Company Key Benefits</h2>
                                {/* faq accordion */}
                                <ServicesDetailsFaqInvestCorporate/>
                            </div>
                        </div>
                    </div>
                </ServicesDetailsWrapper>
            </Layout>
        );


    }

};

export default ServicesDetailsPage;
