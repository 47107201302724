import React from "react";

export const ServicesDetailsFaqLegal = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Risk Mitigation
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Our legal experts help businesses identify and mitigate potential legal risks, ensuring compliance with regulations and minimizing exposure to legal disputes and liabilities.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Comprehensive Guidance
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*type description for customer behavior studies.*/}
                From corporate law compliance to contract drafting and intellectual property protection, our wide range of legal services offers comprehensive support across various areas of business operations.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Customized Solutions
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We understand that each business has unique legal needs. Our consultants work closely with clients to develop tailored solutions that address their specific challenges and objectives effectively.
              </p>
              </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
            >
              Strategic Counsel
            </button>
          </h2>
          <div
              id="collapsefour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*Product and Pricing Analysis*/}
                Beyond just legal compliance, our consultants provide strategic counsel to help businesses make informed decisions that align with their long-term goals and objectives.
                </p>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};
