import React from "react";

export const ServicesDetailsFaqInvestCorporate = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
                Strategic Planning and Alignment
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We assist organizations in developing robust strategic plans that align with their mission, vision, and core values. Our strategic planning process involves conducting thorough SWOT analysis, setting clear objectives, and defining actionable strategies to drive organizational growth and sustainability.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
                Process Optimization
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Our consultants conduct comprehensive process optimization assessments to identify inefficiencies, bottlenecks, and areas for improvement within existing workflows. Through process mapping, automation, and standardization, we help organizations streamline operations, reduce costs, and enhance overall productivity.              </p>
              </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
                Performance Management
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Our performance management solutions help organizations set clear performance expectations, measure progress, and provide feedback to employees. By aligning individual and team performance with organizational goals, we help drive employee engagement, productivity, and overall business performance.
              </p>
              </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
            >
              Corporate Culture and Change Management
            </button>
          </h2>
          <div
              id="collapsefour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We work with organizations to assess, define, and cultivate a positive corporate culture that fosters employee engagement, innovation, and collaboration. Our change management strategies help organizations navigate transitions, mergers, and restructuring while maintaining employee morale and productivity.
              </p>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
