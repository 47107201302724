import React from "react";
import { SERVICES_ONE_LIST } from "../../mocks/services";
import { ServiceOneItem } from "./ServiceOneItem";
import { SlickSlider } from "../SlickSlider/SlickSlider";

export const ServiceOne = () => {
  const slick_settings_brands = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="services-area services-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title white-title text-center mb-50 tg-heading-subheading animation-style2">
              <span className="sub-title tg-element-title">
                Our Services
              </span>
              <h2 className="title tg-element-title">
                We provide exclusive services for your business
              </h2>
              <p>
                We believe in a collaborative and customized approach to management consulting. By working closely with our clients, we gain deep insights into their businesses, enabling us to develop and implement strategies that are precisely aligned with their goals and vision.
              </p>
            </div>
          </div>
        </div>

        <div className="row services-active">
          <SlickSlider settings={slick_settings_brands}>
            {SERVICES_ONE_LIST.map((service) => (
              <div key={service.id} className="col-lg-4">
                <ServiceOneItem service={service} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  );
};
