import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_IMG04,
  SERVICES_IMG05,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-piggy-bank",
    href: "/services-details",
    title: "Finance Planning",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-calculator",
    href: "/services-details",
    title: "Tax File Audit",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-money",
    href: "/services-details",
    title: "Investment Idea",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/services-details",
    title: "Risk Management",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: "flaticon-healthcare",
    href: "/services-details",
    title: "Health Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: "flaticon-protection",
    href: "/services-details",
    title: "Vehicle Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: "flaticon-ship",
    href: "/services-details",
    title: "Cargo Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Fire Insurance",
    subtitle: "Morem ipsum dolor ametey consectre adipiscing.",
  },
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-search",
    href: "/services/marketing-research",
    src: SERVICES_IMG01,
    title: "Marketing Research",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
    items : [
      'Comprehensive market assessments',
      'Customer behavior',
      'Competitive dynamics',
    ]
  },
  {
    id: 2,
    icon: "flaticon-briefcase-1",
    href: "/services/legal-expertise",
    src: SERVICES_IMG02,
    title: "Legal Expertise",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
    items : [
      'Mitigate risks',
      'Strategic decision-making',
      'Enhance legal protection',
    ]
  },
  {
    id: 3,
    icon: "flaticon-investment",
    href: "/services/investment-research",
    src: SERVICES_IMG03,
    title: "Investment Research",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
    items : [
      'Identify opportunities and risks',
      'strategic investment decisions',
      'Due diligence support',
    ]
  },
  {
    id: 4,
    icon: "flaticon-development",
    href: "/services/management-systems",
    src: SERVICES_IMG04,
    title: "Management Systems",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
    items : [
      'Identify inefficiencies and bottlenecks',
      'Optimized management systems',
      'Streamline operations',
    ]
  },
  {
    id: 5,
    icon: "flaticon-handshake",
    href: "/services/corporate-structures",
    src: SERVICES_IMG05,
    title: "Building Corporate Structures and Teams",
    subtitle:
        "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
    items : [
      'Structured Growth',
      'Organizational assessment',
      'Talent management strategies',
    ]
  },
];
