import React from "react";

export const ServicesDetailsFaq = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Market Analysis and Research
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We conduct in-depth market analysis to identify emerging trends, assess competitor strategies, and uncover opportunities for expansion. By analyzing market dynamics and consumer preferences, we help you make informed decisions that align with your business objectives.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Consumer Behavior Studies
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*type description for customer behavior studies.*/}
                Our consumer behavior studies provide valuable insights into customer preferences, purchasing behavior, and brand perceptions. By understanding the factors that influence consumer decisions, we help you develop targeted marketing strategies that resonate with your target audience.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Brand Perception Research
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*Brand Perception Research*/}
                Maintaining a positive brand image is essential for long-term success. We conduct brand perception research to evaluate how your brand is perceived by consumers and stakeholders. Through surveys, focus groups, and data analysis, we identify areas for improvement and develop strategies to enhance brand reputation   </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
            >
              Product and Pricing Analysis
            </button>
          </h2>
          <div
              id="collapsefour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*Product and Pricing Analysis*/}
                We conduct product and pricing analysis to evaluate the performance of your products and services in the market. By analyzing consumer demand, competitor pricing, and market trends, we help you optimize your product offerings and pricing strategies for maximum profitability.
                </p>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};
