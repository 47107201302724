import React from "react";
import { Link } from "react-router-dom";
import { LOGO, W_LOGO } from "../../lib/assets";

export const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <div className="fw-logo">
                    <Link to="/">
                      <img className="fotter-logo-adjust" src={W_LOGO} alt="" />
                    </Link>
                  </div>

                  <div className="footer-content">
                    <p className="text-white">
                      Compass Eight is a trusted partner for businesses seeking strategic guidance and operational excellence
                    </p>
                    {/*<div className="footer-info">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="content">
                            <a href="tel:0123456789">+123 888 9999</a>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="flaticon-clock"></i>
                          </div>
                          <div className="content">
                            <p>
                              Mon – Sat: 8 am – 5 pm, <br />
                              Sunday: <span>CLOSED</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>*/}
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Menu</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/">
                            Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/services/">Services</Link>
                      </li>
                      <li>
                        <Link to="/contact">
                            Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Quick Links</h4>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/services/marketing-research">
                            Marketing Research
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/legal-expertise">
                          Legal Expertise
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/investment-research">
                          Investment Research
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/management-systems">
                          Management Systems
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/corporate-structures">
                            Corporate Structures
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>

                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <a href="tel:0123456789">

                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-clock"></i>
                        </div>
                        <div className="content">
                          <p>
                            Mon – Sat: 8 am – 5 pm, <br /> Sunday:{" "}
                            <span>CLOSED</span>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*<div className="col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h4 className="fw-title">Subscribe to Our Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                      Sign up to Privitar’s weekly newsletter to get the latest
                      updates.
                    </p>
                    <form action="#">
                      <input type="email" placeholder="enter your e-mail" />
                      <button type="submit">Subscribe</button>
                    </form>
                    <span>We don’t send you any spam</span>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
};
