import React from "react";

export const ServicesDetailsFaqInvestmenr = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Market Analysis
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Our investment research team conducts thorough market analysis to identify emerging trends, assess market valuations, and evaluate investment opportunities across various asset classes. By leveraging advanced analytical tools and techniques, we provide clients with timely insights to make informed investment decisions.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Risk Assessment
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We assess investment risks comprehensively, considering factors such as market volatility, economic indicators, and geopolitical developments. Our risk analysis helps clients understand potential downside risks and implement risk mitigation strategies to safeguard their investment portfolios.
             </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Due Diligence Support
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Prior to making investment decisions, conducting thorough due diligence is imperative to assess the quality and viability of investment opportunities. Our research team provides due diligence support, conducting in-depth assessments of potential investments to identify risks and opportunities.
              </p>
              </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
            >
              Investment Recommendations
            </button>
          </h2>
          <div
              id="collapsefour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                {/*Product and Pricing Analysis*/}
                Based on our research findings and analysis, we provide clients with tailored investment recommendations aligned with their investment objectives and risk preferences. Our recommendations are founded on a comprehensive understanding of market dynamics and investment fundamentals.
                </p>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};
