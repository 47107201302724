import React from "react";

export const ServicesDetailsFaqInvestManage = () => {
  return (
    <div className="accordion-wrap-three">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Strategic Planning and Alignment
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                We assist organizations in developing robust strategic plans that align with their mission, vision, and core values. Our strategic planning process involves conducting thorough SWOT analysis, setting clear objectives, and defining actionable strategies to drive organizational growth and sustainability.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Process Optimization
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Our consultants conduct comprehensive process optimization assessments to identify inefficiencies, bottlenecks, and areas for improvement within existing workflows. Through process mapping, automation, and standardization, we help organizations streamline operations, reduce costs, and enhance overall productivity.              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Performance Management
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Effective performance management is essential for aligning individual and team goals with organizational objectives. We design performance management frameworks that incorporate key performance indicators (KPIs), performance appraisal systems, and feedback mechanisms to track progress, recognize achievements, and drive continuous improvement.              </p>
              </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsefour"
                aria-expanded="false"
                aria-controls="collapsefour"
            >
              Change Management
            </button>
          </h2>
          <div
              id="collapsefour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                Implementing new management systems often requires organizational change. Our change management experts work closely with leadership teams and employees to facilitate smooth transitions, overcome resistance to change, and foster a culture of innovation and adaptability. From communication strategies to training programs, we ensure that change initiatives are embraced and embraced effectively.                </p>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};
