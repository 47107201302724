import { TESTI_AVATAR01, TESTI_AVATAR02 } from "../lib/assets";

export const TESTIMONIAL_LIST_TWO = [
  {
    id: 1,
    text: "Morem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elita Florai Sum Dolor Sit Amet, Consecteture.Borem Ipsum Dolor Sit Amet, Consectetur.",
    src: TESTI_AVATAR01,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
  {
    id: 2,
    text: "Morem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elita Florai Sum Dolor Sit Amet, Consecteture.Borem Ipsum Dolor Sit Amet, Consectetur.",
    src: TESTI_AVATAR02,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
  {
    id: 3,
    text: "Morem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elita Florai Sum Dolor Sit Amet, Consecteture.Borem Ipsum Dolor Sit Amet, Consectetur.",
    src: TESTI_AVATAR01,
    title: "Mr.Robey Alexa",
    designation: "CEO, Gerow Agency",
  },
];
