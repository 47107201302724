import React from "react";
import { Link } from "react-router-dom";
import {
  ABOUT_AUTHOR,
  INNER_ABOUT_IMG03,
  INNER_ABOUT_IMG04,
} from "../../lib/assets";

export const AboutEight = () => {
  return (
    <section className="about-area-eight pt-120 pb-120 animation-style2">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="about-img-eight">
              <img src={INNER_ABOUT_IMG03} alt="" />
            </div>
          </div>

          <div className="col-lg-6 animation-style2">
            <div className="about-content-eight">
              <div className="section-title-two mb-30">
                <span className="sub-title">Get To Know More</span>
                <div className="section-title mb-25 tg-heading-subheading animation-style2">
                  <h2 className="title tg-element-title">
                    Our Story
                  </h2>
                </div>
              </div>
              <p>
                Compass Eight Management Consultancies was founded with a vision to fill the gap in the market for comprehensive, results-oriented consulting services. Our journey began with a passion for helping businesses thrive in dynamic environments, and over the years, we have evolved into a trusted advisor for clients across various industries.
              </p>
              <div className="about-content-inner">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-business-presentation"></i>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        Business Consulting
                      </h4>
                      <p>
                        We provide a wide range of consulting services to help businesses achieve their goals.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-investment"></i>
                    </div>
                    <div className="content">
                      <h4 className="title">
                        Financial Consulting
                      </h4>
                      <p>
                        Our financial consulting services are designed to help businesses make informed decisions.
                      </p>
                    </div>
                  </li>
                </ul>

                <div className="right-slide-img">
                  <img src={INNER_ABOUT_IMG04} alt="" />
                </div>
              </div>

              <div className="about-content-bottom">
                <div className="services-btn">
                  <Link to="/services" className="btn">
                    Our Services
                  </Link>
                </div>

                <div className="about-author-info">
                  {/*<div className="thumb">
                    <img src={ABOUT_AUTHOR} alt="" />
                  </div>
*/}
                 {/* <div className="content">
                    <h2 className="title">Mark Stranger</h2>
                    <span>CEO, Gerow Finance</span>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
